<script setup>
  import menu from "~/data/header-menu.json";

  onMounted(() => {
    const btnDismiss = document?.querySelector('button[data-bs-dismiss="offcanvas"]');
    const items = document?.querySelectorAll(".offcanvas-menu-item");
    const onClick = (e) => {
      const href = e.target.getAttribute("href");
      const section = document.querySelector(href);

      section?.scrollIntoView({ behavior: 'smooth' });
      btnDismiss?.click();
    };

    items?.forEach((item) => item?.addEventListener("click", onClick));
  });
</script>
<template>
  <button type="button"
          data-bs-target="#sidebar"
          data-bs-toggle="offcanvas"
          aria-controls="sidebar"
          aria-label="Toggle menu"
          class="navbar-toggler border-0 shadow-none px-0">
    <span class="text-uppercase text-white text-menu ff-montserrat menu-link">Menu</span>
  </button>
  <div id="sidebar" aria-labelledby="sidebar-label" class="offcanvas offcanvas-end text-bg-dark">
    <div class="offcanvas-header justify-content-end bg-mage2">
      <HeaderLogo class="d-md-none d-block"/>
      <button type="button"
              aria-label="Close"
              data-bs-dismiss="offcanvas"
              class="ms-auto btn btn-link text-decoration-none border-0 shadow-none px-0">
        <span class="text-white text-uppercase text-menu ff-montserrat">Close</span>
      </button>
    </div>
    <div class="offcanvas-body navbar-nav justify-content-lg-end flex-grow-1 bg-transparent justify-content-start" id="navbar-mobile">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <template v-for="item in menu?.menuItems?.nodes">
          <li class="nav-item me-5">
              <a class="nav-link offcanvas-menu-item" aria-current="page"
                  :aria-label="item?.label?.toUpperCase()"
                  :href="item.uri">
                  {{ item?.label?.toUpperCase() }}
              </a>
          </li>
        </template>
          <li class="nav-item">
              <a class="nav-link navbar-link-btn" aria-current="page"
                  aria-label="GET IN TOUCH"
                  :href="'#'">
                  GET IN TOUCH
              </a>
          </li>
      </ul>
    </div>
  </div>
</template>
