<script setup lang="ts">
    const props = defineProps<{
        additionalClass?: string
    }>();
</script>
<template>
    <footer :class="(props?.additionalClass || '') + ' footer py-4 bg-dark-primary'">
        <div class="container">
            <div class="copyright text-white">© 2024 Mage 2</div>
        </div>
    </footer>
</template>