<script setup lang="ts">
import {siteOptionsQuery} from "~/composables/GraphQlQueries";

const data = await useGQlData(siteOptionsQuery);
</script>

<template>
    <div class="navbar-logo">
      <a class="navbar-brand" href="/">
         <NuxtImg v-if="data?.page?.siteOptions?.logo?.node?.mediaItemUrl"
                  preload
                  loading="lazy"
                  id="navbar-logo"
                  :src="data?.page?.siteOptions?.logo?.node?.mediaItemUrl"
                  alt="Mage2 Logo"
                  format="webp"/>
        <span v-else>
          <span class="text-white me-2" style="font-size: 22px;font-weight: 700; vertical-align: middle;">mage</span>
          <span class="text-white" style="font-size: 40px; font-weight: 700; vertical-align: middle;">2</span>
        </span>
      </a>
    </div>
</template>

<style scoped lang="css">
#navbar-logo {
  width: auto;
  height: 50px;
  max-height: 50px;
  padding: 5px 0;
}

@media (max-width: 767.98px) {
  #navbar-logo {
    height: 40px !important;
  }
}
</style>
